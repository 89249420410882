import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class UrlService {
    private baseApiUri: string;

    constructor() {
        this.baseApiUri =
            environment.dvApiUrl.replace('/api/v1', '');
    }

    generateProviderUrl(
        provider: string = '',
        action: string = '',
        klientId: number = 0
    ): string {
        let concatenatedUrlForAction = this.baseApiUri;

        if (klientId > 0) {
            concatenatedUrlForAction += '/klient/' + klientId;
        }

        if (provider !== '' || provider !== undefined) {
            concatenatedUrlForAction += '/external/' + provider;
        }

        if (action !== '' || action !== undefined) {
            concatenatedUrlForAction += '/' + action;
        }

        return concatenatedUrlForAction;
    }

    getProviderUrl(
        providerShortName: string,
        type: string,
        action: string,
        clientId: number
    ): string {
        let url =
            this.baseApiUri +
            'api/' +
            providerShortName +
            '/client/' +
            clientId +
            '/' +
            type +
            '/' +
            action;

        return url;
    }

    getProviderAuthenticateUrl(
        providerShortName: string,
        clientId: number,
        userId: number
    ) {
        return (
            this.baseApiUri +
            '/api/' +
            providerShortName +
            '/authenticate/client/' +
            clientId +
            '/user/' +
            userId
        );
    }
}
